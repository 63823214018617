import React from 'react';

import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { antdThemeConfig } from 'shared/configs/antdTheme';
import { queryClient } from 'shared/configs/reactQuery/reactQuery';

import App from './App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
	<React.StrictMode>
		<ConfigProvider theme={antdThemeConfig}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</ConfigProvider>
	</React.StrictMode>,
);
