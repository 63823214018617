import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';

import { Flex } from 'antd';
import { SubscriptionDto } from 'generated-types';
import { TSubscriptionFormData } from 'shared/api/subscription.service';

import styles from './RegistrationPlanFormStepTwo.module.scss';
import FormActions from './ui/FormActions';
import FormCardContainer from './ui/FormCardContainer';
import Summary from './ui/Summary';
import UserInfoCardForm from './ui/UserInfoCardForm';

type TRegistrationPlanFormStepTwoProps = PropsWithChildren & {
	subscriptionResponse: SubscriptionDto;
	seSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
	setFormStep: Dispatch<SetStateAction<number>>;
	isProcessing: boolean;
	setFormStepsData: Dispatch<SetStateAction<TSubscriptionFormData>>;
};

const RegistrationPlanFormStepTwo: FC<TRegistrationPlanFormStepTwoProps> = ({
	children,
	subscriptionResponse,
	seSubscriptionResponse,
	setFormStep,
	isProcessing,
	setFormStepsData,
}): JSX.Element => {
	return (
		<Flex className={styles.container} vertical justify="center" align="flex-start">
			<h1 className={styles.title}>Try Corvus Link</h1>
			<h2 className={styles.subTitle}>
				<strong>7 days free</strong> (no credit card required)&nbsp;
				<span className={styles.subTitleText}>
					/ Then ${subscriptionResponse.amount! / 100} per month
				</span>
			</h2>
			<Flex className={styles.content} justify="space-between" gap={24}>
				<FormCardContainer title="Account Details">
					<UserInfoCardForm seSubscriptionResponse={seSubscriptionResponse} />
					<Flex gap={16}>{children}</Flex>
				</FormCardContainer>
				<FormCardContainer title="Subscription Details">
					<Summary
						subscriptionResponse={subscriptionResponse}
						seSubscriptionResponse={seSubscriptionResponse}
					/>
					<FormActions
						setFormStep={setFormStep}
						isProcessing={isProcessing}
						setFormStepsData={setFormStepsData}
					/>
				</FormCardContainer>
			</Flex>
		</Flex>
	);
};

export default RegistrationPlanFormStepTwo;
