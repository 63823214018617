import { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import { Button } from 'antd';
import { SubscriptionDto } from 'generated-types';

import SignUpInitiatives from './ui/SignUpInitiatives';

type TSignupFormStepTwoProps = {
	subscriptionResponse: SubscriptionDto | null;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto | null>>;
};

const SignupFormStepTwo: FC<TSignupFormStepTwoProps> = ({
	subscriptionResponse,
	setSubscriptionResponse,
}): ReactElement => {
	console.log({ subscriptionResponse });
	return (
		<>
			<SignUpInitiatives />
			<Button
				// className={classNames(styles.button, styles.sendButton)}
				type="primary"
				// shape="circle"
				form="sign-up-form"
				htmlType="submit"
				// disabled={!value.trim().length || isProcessing}
				// icon={<ArrowUpOutlined style={{ fontSize: 16 }} />}
				// loading={isProcessing}
			>
				Sign Me Up
			</Button>
		</>
	);
};

export default SignupFormStepTwo;
