/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SubscriptionRequest {
	/** @uniqueItems true */
	subscriptionPlanTypes: ('PLAN' | 'BUILD' | 'GROW' | 'ENTERPRISE')[];
	/** @uniqueItems true */
	subscriptionAddonTypes?: ('INDUSTRY_REPORTS' | 'PROCESS_REPORTS')[];
}

export interface SubscriptionDto {
	tierProductType?:
		| 'TIER_1'
		| 'TIER_2'
		| 'TIER_3'
		| 'INDUSTRY_REPORTS_ADDON'
		| 'PROCESS_REPORTS_ADDON'
		| 'TIER_1_TOKEN'
		| 'TIER_2_TOKEN'
		| 'TIER_3_TOKEN';
	tierProductPrice?: number;
	tierTokenProductType?:
		| 'TIER_1'
		| 'TIER_2'
		| 'TIER_3'
		| 'INDUSTRY_REPORTS_ADDON'
		| 'PROCESS_REPORTS_ADDON'
		| 'TIER_1_TOKEN'
		| 'TIER_2_TOKEN'
		| 'TIER_3_TOKEN';
	tierTokenProductPrice?: number;
	reportsAddonPrices?: Record<string, number>;
	/** @format int32 */
	numberOfLicenses?: number;
	amount?: number;
	couponId?: string;
	amountDiscount?: number;
	percentageDiscount?: number;
}

export interface CustomerRequest {
	firstName?: string;
	lastName?: string;
	email: string;
	/** @pattern ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*<>-]).{8,}$ */
	password: string;
	captcha: string;
}

export interface SignUpInitiativeRequest {
	query: string;
	captcha: string;
}

export interface IdResponse {
	id?: string;
}

export interface EnterpriseSubscriptionRequest {
	firstName: string;
	lastName: string;
	email: string;
	contactPhone?: string;
	companyName?: string;
}

export interface ExternalAuthenticationRequest {
	authorizationCode: string;
}

export interface ExternalUserResponse {
	firstName?: string;
	lastName?: string;
	email?: string;
}

export interface SubscriptionNumberOfLicensesRequest {
	/**
	 * @format int32
	 * @min 1
	 * @max 99
	 */
	numberOfLicenses: number;
}

export interface ValidationResponse {
	isValid?: boolean;
}

export interface SignUpInitiativeToolResponse {
	applicationModuleType?:
		| 'WORKSPACES'
		| 'PLAN'
		| 'FORECAST'
		| 'ACTION_PLAN'
		| 'OPERATING_MODEL'
		| 'PROJECTS'
		| 'RETROSPECTIVES'
		| 'DIAGRAMS'
		| 'OPTIMIZATION'
		| 'INDUSTRY_REPORTS'
		| 'PROCESS_REPORTS'
		| 'FILE_EXPLORER'
		| 'STORYBOARD';
	isChecked?: boolean;
	/** @format int32 */
	priority?: number;
	recommendation?: string;
	example?: string;
}

export enum AIAnalysisProgressType {
	NOT_STARTED = 'NOT_STARTED',
	WAITING_FOR_AI_RESPONSE = 'WAITING_FOR_AI_RESPONSE',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

export interface SubscriptionPricesResponse {
	subscriptionPrices?: Record<string, number>;
}

export interface ErrorResponse {
	/** @format int64 */
	timestamp?: number;
	message?: string;
	responseStatus?:
		| 'VALIDATION_EXCEPTION'
		| 'INVALID_CAPTCHA_EXCEPTION'
		| 'RESOURCE_NOT_FOUND_EXCEPTION'
		| 'SESSION_NOT_FOUND_EXCEPTION'
		| 'UNSUPPORTED_COUPON'
		| 'EXPIRED_COUPON'
		| 'INVALID_SETUP_INTENT_STATUS'
		| 'USER_ALREADY_EXISTS_EXCEPTION'
		| 'INVALID_COUPON_EXCEPTION'
		| 'NOT_FOUND'
		| 'BAD_REQUEST'
		| 'INTERNAL_SERVER_ERROR';
}
