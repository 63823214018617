import { ThemeConfig } from 'antd';

export const antdThemeConfig: ThemeConfig = {
	token: {
		colorPrimary: '#00AC8E',
		colorPrimaryHover: '#33BDA5',
		colorPrimaryActive: '#006162',
		colorInfo: '#3498DB',
		colorInfoHover: '#3498DB',
		colorError: '#D03900',
		colorErrorHover: 'rgba(208, 57, 0, 0.6)',
		colorErrorActive: '#9A2A00',
		colorSuccess: '#00AC8E',
		fontFamily: `'Jost', sans-serif`,
		colorText: '#1A2026',
		colorTextSecondary: '#1A2026',
		colorTextDisabled: '#BFBFBF',
		controlHeight: 40,
		fontWeightStrong: 600,
	},
	components: {
		Typography: {
			fontSizeHeading1: 32,
			fontSizeHeading2: 28,
			fontSizeHeading3: 22,
			fontSizeHeading4: 18,
			fontSize: 16,
			lineHeightHeading1: 0.6666,
			titleMarginBottom: 0,
			marginXS: 0,
			marginXXS: 0,
			paddingSM: 0,
		},
		Button: {
			contentFontSize: 16,
			fontWeight: 500,
			defaultColor: '#1A2026',
		},
		Checkbox: {
			colorPrimary: '#006162',
			colorBgContainer: '#F2FBF9',
			colorPrimaryActive: '#006162',
			colorPrimaryHover: '#006162',
			borderRadiusSM: 6,
			colorBorder: '#006162',
			controlInteractiveSize: 32,
		},
		Modal: {
			titleFontSize: 24,
		},
	},
};
