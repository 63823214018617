import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { ReactComponent as LinkTokenIcon } from 'assets/svg/linkToken.svg';
import classNames from 'classnames';

import styles from './Tier.module.scss';

const { Title, Text } = Typography;

type TTierProps = {
	price?: number;
	title: string;
	tokenPrice?: number;
};

const Tier: FC<TTierProps> = ({ price = 0, title, tokenPrice = 0 }): JSX.Element => {
	const tokenPriceInDollars = tokenPrice / 100;
	const factor = 1e12;
	const precisePrice = Math.round(tokenPriceInDollars * factor) / factor;

	return (
		<Flex className={classNames(styles.container)} justify="center" align="center" vertical>
			<Flex className={classNames(styles.titleContainer)} justify="space-between" align="center">
				<Title level={2} className={styles.title}>
					{title}
				</Title>
				<Flex align="flex-end">
					<Title level={2} className={styles.price}>
						{`$${price / 100}`}
					</Title>
					<Text className={styles.text}> {`/month`}</Text>
				</Flex>
			</Flex>
			<Flex vertical className={styles.additionalInfo} gap={16} align="flex-start" justify="center">
				<Title level={4} className={styles.additionalInfoTitle}>
					Additional usage based charges:
				</Title>
				<Flex justify="space-between" align="center" style={{ width: '100%' }}>
					<Flex gap={8} align="center">
						<LinkTokenIcon />
						<Text className={styles.text18}>Link Token</Text>
					</Flex>
					<Flex gap={8} align="center">
						<Text className={styles.text14}>per token / billed monthly</Text>

						<Text className={classNames(styles.text18, styles.bold)}>${precisePrice}</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Tier;
