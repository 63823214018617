import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Checkbox, Flex, Form, Typography } from 'antd';
import { ADD_ONS_VALUES, TSubscriptionPricesInner } from 'shared/api/subscription.service';
import CustomModal from 'shared/ui/CustomModal';

import AddOnsCard from './AddOnsCard';

const { Text } = Typography;

type TAddOnsModalProps = {
	isLoading: boolean;
	showModal: boolean;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	prices: TSubscriptionPricesInner | undefined;
};

const AddOnsModal: FC<TAddOnsModalProps> = ({
	isLoading,
	showModal,
	setShowModal,
	prices,
}): JSX.Element => {
	const onClose = (): void => {
		setShowModal(false);
	};

	return (
		<CustomModal
			style={{ maxWidth: 660 }}
			width={660}
			title="Add-ons"
			open={showModal}
			onCancel={onClose}
			confirmLoading={isLoading}
			footer={
				<>
					<Button disabled={isLoading} onClick={onClose} style={{ width: '100%' }}>
						Back
					</Button>
					<Button
						style={{ width: '100%' }}
						htmlType="submit"
						type="primary"
						form="registration-form"
						loading={isLoading}
						disabled={isLoading}
					>
						Checkout
					</Button>
				</>
			}
			destroyOnClose
			centered
		>
			<Flex justify="center" align="center" gap={24} vertical>
				<Text>Elevate your productivity: Add Industry Reports to your toolkit today!</Text>
				<Form.Item name="subscriptionAddonTypes">
					<Checkbox.Group style={{ gap: 12 }}>
						<AddOnsCard
							value={ADD_ONS_VALUES.INDUSTRY_REPORTS}
							addonPrice={prices?.INDUSTRY_REPORTS_ADDON}
							label="Industry Reports"
						/>
						<AddOnsCard
							value={ADD_ONS_VALUES.PROCESS_REPORTS}
							addonPrice={prices?.PROCESS_REPORTS_ADDON}
							label="Process Reports"
						/>
					</Checkbox.Group>
				</Form.Item>
			</Flex>
		</CustomModal>
	);
};

export default AddOnsModal;
