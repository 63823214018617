import { Dispatch, FC, SetStateAction } from 'react';

import { Form, Input, InputNumber, message } from 'antd';
import { SubscriptionDto } from 'generated-types';
import { debounce } from 'lodash';
import { useMutation } from 'react-query';
import { changeNumberOfLicenses } from 'shared/api/subscription.service';
import { validateNumberOfLicenses } from 'shared/lib/lib';

type TUserInfoCardFormProps = {
	seSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
};

const UserInfoCardForm: FC<TUserInfoCardFormProps> = ({ seSubscriptionResponse }): JSX.Element => {
	const { mutateAsync: changeNumberOfLicensesAsync } = useMutation({
		mutationFn: (value: number) => changeNumberOfLicenses(value),
	});

	const handleNumberOfLicensesChange = debounce(async (value: number): Promise<void> => {
		if (value >= 100) return;

		try {
			const response = await changeNumberOfLicensesAsync(value);
			seSubscriptionResponse(response);
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	}, 1000);

	return (
		<>
			<Form.Item
				label="First Name"
				name="firstName"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input placeholder="Enter First Name" />
			</Form.Item>
			<Form.Item
				label="Last Name"
				name="lastName"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input placeholder="Enter Last Name" />
			</Form.Item>

			<Form.Item
				label="Email"
				name="email"
				rules={[{ required: true, message: 'Required field', whitespace: true }]}
			>
				<Input type="email" placeholder="Enter Email" />
			</Form.Item>
			<Form.Item
				label="Number of Licenses Needed"
				name="numberOfLicenses"
				rules={[
					{
						validator: validateNumberOfLicenses,
						message: 'Maximum Number of Licenses is 99',
					},
				]}
			>
				<InputNumber
					type="number"
					min={1}
					placeholder="Enter Number of Licenses"
					style={{ width: '100%' }}
					onChange={handleNumberOfLicensesChange}
				/>
			</Form.Item>
			<Form.Item
				label="Password"
				name="password"
				rules={[{ required: true, message: 'Required field' }]}
			>
				<Input.Password id="password" type="password" placeholder="Enter Password" />
			</Form.Item>
			<Form.Item
				label="Confirm Password"
				name="password2"
				dependencies={['password']}
				rules={[
					{
						required: true,
					},
					({ getFieldValue }): any => ({
						validator(_, value): Promise<void> {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve();
							}
							return Promise.reject(new Error('The new password that you entered do not match!'));
						},
					}),
				]}
			>
				<Input.Password type="password" placeholder="Enter Password" />
			</Form.Item>
		</>
	);
};

export default UserInfoCardForm;
