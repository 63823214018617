import { CheckboxOptionType } from 'antd';

export const initiativesOptions: (CheckboxOptionType<string> & {
	recommendation: string;
	example: string;
})[] = [
	{
		label: 'Plan',
		value: 'PLAN',
		recommendation:
			'Create comprehensive business, strategic, or operating plans with AI-driven insights and current industry insights.',
		example:
			'Develop a strategic plan for the next fiscal year or a business plan for a new business.',
	},
	{
		label: 'Forecast',
		value: 'FORECAST',
		recommendation:
			'Generate AI-powered revenue and demand forecasts based on your product and sales data.',
		example:
			'Predict next quarter’s sales trends and receive a detailed analysis explaining key demand drivers.',
	},
	{
		label: 'Action Plan',
		value: 'ACTION_PLAN',
		recommendation:
			'Manage work with task lists, boards, calendars, workflows, and collaboration tools to execute your strategy effectively.',
		example:
			'Create an action plan for a new product launch and track progress with team collaboration features.',
	},
	{
		label: 'Operating Model',
		value: 'OPERATING_MODEL',
		recommendation:
			'AI-generated operating models with recommended structures, processes, and governance frameworks for business optimization.',
		example:
			'Define a scalable operating model for expanding into new markets, covering governance and risk management.',
	},
	{
		label: 'Projects',
		value: 'PROJECTS',
		recommendation:
			'Plan and execute projects with work management tools, Gantt charts, Kanban boards, dashboards, and dependency tracking.',
		example:
			'Manage a product development project by visualizing milestones and tracking resource allocation.',
	},
	{
		label: 'Retrospectives',
		value: 'RETROSPECTIVES',
		recommendation:
			'Conduct after-action reviews with AI-supported sticky notes, solution tracking, and collaboration tools.',
		example:
			'Run a retrospective after a product launch to gather insights and track solution implementation.',
	},
	{
		label: 'Diagrams',
		value: 'DIAGRAMS',
		recommendation:
			'Design process flows, visual plans, and conceptual models using an interactive digital canvas.',
		example:
			'Create a visual representation of your customer journey to identify improvement opportunities.',
	},
	{
		label: 'Optimization',
		value: 'OPTIMIZATION',
		recommendation:
			'Drive continuous improvement using AI-assisted insights, Lean Six Sigma tools, and stakeholder engagement features.',
		example:
			'Optimize a business process with AI-driven analysis and process improvement recommendations.',
	},
	{
		label: 'File Explorer',
		value: 'FILE_EXPLORER',
		recommendation:
			'Store and organize files while automatically capturing documents generated across various tools.',
		example:
			'Access all project-related documents in one centralized location without manual uploads.',
	},
	{
		label: 'Storyboard',
		value: 'STORYBOARD',
		recommendation:
			'Build reports, presentations, and other work products using story blocks with various features.',
		example: 'Create a playbook by combining project visuals, reports, and strategic action items.',
	},
	{
		label: 'Industry Reports',
		value: 'INDUSTRY_REPORTS',
		recommendation:
			'Access comprehensive industry insights, trends, outlooks, and strategic analyses for informed decision-making.',
		example:
			'Stay ahead of competitors by reviewing the latest industry performance and strategic priorities.',
	},
	{
		label: 'Process Reports',
		value: 'PROCESS_REPORTS',
		recommendation:
			'Get detailed guides on key business processes, including steps, and success factors.',
		example:
			'Improve operational efficiency by referencing best practices for supply chain management.',
	},
];
