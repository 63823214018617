import { FC } from 'react';

import { Flex, Form } from 'antd';
import { TSubscriptionPricesInner } from 'shared/api/subscription.service';

import styles from './RegistrationPlanFormStepOne.module.scss';
import AddOnsModalWithButton from './ui/AddOnsModalWithButton';
import PlansCardsContainer from './ui/PlansCardsContainer';
import TiersContainer from './ui/TiersContainer';

type TRegistrationPlanFormStepOneProps = {
	isLoading: boolean;
	prices: TSubscriptionPricesInner | undefined;
};

const RegistrationPlanFormStepOne: FC<TRegistrationPlanFormStepOneProps> = ({
	isLoading,
	prices,
}): JSX.Element => {
	const subscriptionPlanTypesLength = Form.useWatch('subscriptionPlanTypes')?.length || 0;

	return (
		<Flex vertical justify="center" align="center" className={styles.container} gap={24}>
			<PlansCardsContainer />
			<TiersContainer prices={prices} />
			<Flex style={{ width: '100%' }} justify="center" gap={24}>
				<AddOnsModalWithButton
					disabled={subscriptionPlanTypesLength === 0}
					className={styles.button}
					type="primary"
					isLoading={isLoading}
					prices={prices}
				/>
			</Flex>
		</Flex>
	);
};

export default RegistrationPlanFormStepOne;
