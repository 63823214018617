import { ReactElement, useCallback, useState } from 'react';

import { Flex, Form, message } from 'antd';
import { SignupFormStepOne } from 'fetures/SignupFormSteps';
import SignupFormStepTwo from 'fetures/SignupFormSteps/ui/SignupFormStepTwo';
import {
	SignUpInitiativeRequest,
	SignUpInitiativeToolResponse,
	SubscriptionDto,
	SubscriptionRequest,
} from 'generated-types';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation, useQuery } from 'react-query';
import {
	billingQueryKeys,
	createSignUpInitiatives,
	createSubscription,
	getSignUpInitiativeTools,
} from 'shared/api/subscription.service';
import { useGetSignUpInitiativeStatusPolling } from 'shared/hooks/useGetSignUpInitiativeStatusPolling';
import WithLoader from 'shared/ui/WithLoader';

import { getSubscriptionPayload } from './SignupForm.lib';
import styles from './SignupForm.module.scss';

const SignupForm = (): ReactElement => {
	const [form] = Form.useForm();
	const [formStep, setFormStep] = useState<0 | 1>(0);
	const [signUpInitiativeId, setSignUpInitiativeId] = useState<string | null>(null);
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const [subscriptionResponse, setSubscriptionResponse] = useState<SubscriptionDto | null>(null);

	const [isProcessing, setIsProcessing] = useState(false);

	const { refetch } = useQuery({
		...billingQueryKeys.billing.initiativeTools({ signUpInitiativeId }),
		queryFn: () => getSignUpInitiativeTools(signUpInitiativeId!),
		enabled: false,
	});

	const { mutateAsync: createSignUpInitiativesAsync } = useMutation({
		mutationFn: (payload: SignUpInitiativeRequest) => createSignUpInitiatives(payload),
	});

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: SubscriptionRequest) => createSubscription(formValues),
	});

	const setTokenFunc = useCallback((getToken) => {
		setRecaptchaToken(getToken);
	}, []);

	const handleSetRefreshReCaptcha = useCallback(() => {
		setRefreshReCaptcha((prev) => !prev);
	}, []);

	const formSetter = (
		tools: SignUpInitiativeToolResponse[],
	): {
		checkedTools: SignUpInitiativeToolResponse[];
		toolsToSet: SignUpInitiativeToolResponse['applicationModuleType'][];
	} => {
		const checkedTools = tools.filter((tool) => tool.isChecked);
		const toolsToSet = checkedTools.map((tool) => tool.applicationModuleType);
		form.setFieldValue('initiatives', toolsToSet);

		return { checkedTools, toolsToSet };
	};

	const completedCallback = async (): Promise<void> => {
		try {
			const { data: toolsResponse = [] } = await refetch();
			const { toolsToSet } = formSetter(toolsResponse);
			const payload = getSubscriptionPayload(toolsToSet);
			const response = await createSubscriptionAsync(payload);
			setSubscriptionResponse(response);
			setFormStep(1);
			handleSetRefreshReCaptcha();
		} catch (e) {
			message.error(e?.response?.data?.message || e.message || 'Error!');
		} finally {
			setIsProcessing(false);
		}
	};

	useGetSignUpInitiativeStatusPolling({ signUpInitiativeId, completedCallback });
	console.log({ version: 1 });
	const onFinish = async (formValues): Promise<void> => {
		if (!recaptchaToken) return;

		try {
			if (formStep === 0) {
				setIsProcessing(true);
				const payload = {
					query: formValues.query,
					captcha: recaptchaToken,
				};
				const { id } = await createSignUpInitiativesAsync(payload);
				setSignUpInitiativeId(id!);
			}
			if (formStep === 1) {
				console.log(formValues);
			}
		} catch (e) {
			setIsProcessing(false);
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<WithLoader isLoading={false}>
			<Flex justify="center" gap={48} className={styles.container} id="form-container">
				<Form
					requiredMark={false}
					id="sign-up-form"
					form={form}
					layout="vertical"
					onFinish={onFinish}
					validateTrigger="onChange"
				>
					<GoogleReCaptcha onVerify={setTokenFunc} refreshReCaptcha={refreshReCaptcha} />
					{formStep === 0 && (
						<SignupFormStepOne
							isProcessing={isProcessing}
							setIsProcessing={setIsProcessing}
							setFormStep={setFormStep}
							setSubscriptionResponse={setSubscriptionResponse}
						/>
					)}
					{formStep === 1 && (
						<SignupFormStepTwo
							subscriptionResponse={subscriptionResponse}
							setSubscriptionResponse={setSubscriptionResponse}
						/>
					)}
				</Form>
			</Flex>
		</WithLoader>
	);
};

export default SignupForm;
