import { Dispatch, FC, ReactElement, SetStateAction, useEffect } from 'react';

import { ArrowUpOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Typography, message } from 'antd';
import { ReactComponent as MicrophoneIcon } from 'assets/svg/microphone-icon.svg';
import classNames from 'classnames';
import {
	SignUpInitiativeToolResponse,
	SubscriptionDto,
	SubscriptionRequest,
} from 'generated-types';
import { ShowCountFormatter } from 'rc-input/lib/interface';
import { useMutation } from 'react-query';
import { createSubscription } from 'shared/api/subscription.service';
import { useSpeechToTextWebApi } from 'shared/hooks/useSpeechToTextWebApi';
import { getSubscriptionPayload } from 'widgets/SignupForm/SignupForm.lib';
import { SHORTCUT_BUTTONS } from 'widgets/SignupForm/SignupForm.types';

import {
	agileProductDevelopmentFormValues,
	buttonItems,
	improveProcessesFormValues,
	manageProjectsFormValues,
	runRetroOrAARFormValues,
	startBusinessFormValues,
	startBusinessLightFormValues,
} from './SignupFormStepOne.constants';
import styles from './SignupFormStepOne.module.scss';

const { TextArea } = Input;

type TSignupFormStepOneProps = {
	isProcessing: boolean;
	setIsProcessing: Dispatch<SetStateAction<boolean>>;
	setFormStep: Dispatch<SetStateAction<0 | 1>>;
	setSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
};

const SignupFormStepOne: FC<TSignupFormStepOneProps> = ({
	isProcessing,
	setIsProcessing,
	setFormStep,
	setSubscriptionResponse,
}): ReactElement => {
	const { toggleListenSpeech, isListeningSpeechToText, value, setValue } = useSpeechToTextWebApi();
	const form = Form.useFormInstance();

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: SubscriptionRequest) => createSubscription(formValues),
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setValue(e.target.value);
	};

	useEffect(() => {
		form.setFieldValue('query', value);
	}, [value, form]);

	const formatter: ShowCountFormatter = ({ count, maxLength }) => (
		<>
			{count} / {maxLength} characters
		</>
	);

	const handleShortcutPress = async (key: SHORTCUT_BUTTONS): Promise<void> => {
		const shortcutFormValuesMap: Record<
			SHORTCUT_BUTTONS,
			SignUpInitiativeToolResponse['applicationModuleType'][]
		> = {
			[SHORTCUT_BUTTONS.startBusiness]: startBusinessFormValues,
			[SHORTCUT_BUTTONS.startBusinessLite]: startBusinessLightFormValues,
			[SHORTCUT_BUTTONS.manageProjects]: manageProjectsFormValues,
			[SHORTCUT_BUTTONS.agileProductDevelopment]: agileProductDevelopmentFormValues,
			[SHORTCUT_BUTTONS.runRetroOrAAR]: runRetroOrAARFormValues,
			[SHORTCUT_BUTTONS.improveProcesses]: improveProcessesFormValues,
		};

		const formValues = shortcutFormValuesMap[key];

		if (formValues) {
			form.setFieldsValue({ initiatives: formValues });

			try {
				setIsProcessing(true);
				const payload = getSubscriptionPayload(formValues);
				const response = await createSubscriptionAsync(payload);
				setSubscriptionResponse(response);
				setFormStep(1);
			} catch (e) {
				message.error(e?.response?.data?.message || e.message || 'Error!');
			} finally {
				setIsProcessing(false);
			}
		}
	};

	return (
		<div style={{ width: '100%' }}>
			<Typography.Title
				level={2}
				style={{
					fontSize: 44,
					color: 'var(--corvus-green-secondary)',
				}}
			>
				How can we help you?
			</Typography.Title>
			<Typography.Title level={3} style={{ marginTop: 4 }}>
				7-day free trial <span style={{ fontWeight: 400 }}>(no credit card required)</span>
			</Typography.Title>
			<div className={styles.inputWrapper}>
				<Form.Item name="query">
					<TextArea
						onChange={handleChange}
						value={value}
						classNames={{
							count: styles.count,
							textarea: styles.textArea,
						}}
						rows={2}
						showCount={{
							formatter,
						}}
						maxLength={200}
						placeholder="Ex. I have a remote team and I want to see all of our work in one place."
						className={styles.input}
					/>
				</Form.Item>
				<Button
					onClick={toggleListenSpeech}
					className={classNames(styles.button, styles.micButton, {
						[styles.active]: isListeningSpeechToText,
					})}
					type="primary"
					shape="circle"
					icon={<MicrophoneIcon style={{ fontSize: 16 }} />}
					loading={isProcessing}
					disabled={isProcessing}
				/>
				<Button
					className={classNames(styles.button, styles.sendButton)}
					type="primary"
					shape="circle"
					form="sign-up-form"
					htmlType="submit"
					disabled={!value.trim().length || isProcessing}
					icon={<ArrowUpOutlined style={{ fontSize: 16 }} />}
					loading={isProcessing}
				/>
			</div>
			<Flex vertical gap={12}>
				{buttonItems.map((row, parentIdx) => (
					<Flex key={parentIdx} justify="center" gap={8}>
						{row.map(({ icon: Icon, title, key }) => (
							<Button
								key={key}
								className={styles.promptButton}
								disabled={isProcessing}
								onClick={(): Promise<void> => handleShortcutPress(key)}
							>
								<Flex align="center" gap={8}>
									<Icon color="var(--corvus-green)" />
									<span>{title}</span>
								</Flex>
							</Button>
						))}
					</Flex>
				))}
			</Flex>
		</div>
	);
};

export default SignupFormStepOne;
