import { FC, useCallback, useState } from 'react';

import { Flex } from 'antd';
import MicrosoftAuthButton from 'fetures/MicrosoftAuthButton';
import {
	RegistrationPlanFormStepOne,
	RegistrationPlanFormStepTwo,
} from 'fetures/RegistrationPlanFormSteps';
import { SubscriptionDto } from 'generated-types';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useQuery } from 'react-query';
import { TSubscriptionFormData, getPrices } from 'shared/api/subscription.service';
import WithLoader from 'shared/ui/WithLoader';

import styles from './RegistrationPlanForm.module.scss';
import FormWrapper from './ui/FormWrapper';

const RegistrationPlanForm: FC = (): JSX.Element => {
	const [formStep, setFormStep] = useState(0);

	const [formStepsData, setFormStepsData] = useState<TSubscriptionFormData | null>(null);
	const [subscriptionResponse, seSubscriptionResponse] = useState<SubscriptionDto | null>(null);
	const [isProcessing, setIsProcessing] = useState(false);
	const [token, setToken] = useState<string | null>(null);
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

	const { data: prices, isLoading: isLoadingPrices } = useQuery({
		queryFn: () => getPrices(),
		queryKey: ['prices'],
		select: (data) => data.subscriptionPrices,
	});

	const setTokenFunc = useCallback((getToken) => {
		setToken(getToken);
	}, []);

	const handleSetRefreshReCaptcha = useCallback(() => {
		setRefreshReCaptcha((prev) => !prev);
	}, []);

	console.log({ refreshReCaptcha });
	return (
		<WithLoader isLoading={isLoadingPrices}>
			<Flex justify="center" gap={48} className={styles.container} id="form-container">
				{formStep === 0 && (
					<FormWrapper
						setFormStepsData={setFormStepsData}
						seSubscriptionResponse={seSubscriptionResponse}
						setIsProcessing={setIsProcessing}
						setFormStep={setFormStep}
						formStep={formStep}
						formStepsData={formStepsData}
					>
						<RegistrationPlanFormStepOne isLoading={isProcessing} prices={prices} />
					</FormWrapper>
				)}
				{formStep === 1 && subscriptionResponse && (
					<FormWrapper
						setFormStepsData={setFormStepsData}
						seSubscriptionResponse={seSubscriptionResponse}
						setIsProcessing={setIsProcessing}
						setFormStep={setFormStep}
						formStep={formStep}
						formStepsData={formStepsData}
						handleSetRefreshReCaptcha={handleSetRefreshReCaptcha}
						recaptchaToken={token}
					>
						<>
							<GoogleReCaptcha onVerify={setTokenFunc} refreshReCaptcha={refreshReCaptcha} />
							<RegistrationPlanFormStepTwo
								subscriptionResponse={subscriptionResponse}
								seSubscriptionResponse={seSubscriptionResponse}
								setFormStep={setFormStep}
								isProcessing={isProcessing}
								setFormStepsData={setFormStepsData}
							>
								{/* <GoogleAuthButton setFormStepsData={setFormStepsData} /> */}
								<MicrosoftAuthButton setFormStepsData={setFormStepsData} />
							</RegistrationPlanFormStepTwo>
						</>
					</FormWrapper>
				)}
			</Flex>
		</WithLoader>
	);
};

export default RegistrationPlanForm;
