export const handleMicrosoftLogin = (): void => {
	const clientId = (): string => process.env.REACT_APP_MICROSOFT_CLIENT_ID!;
	const redirectUri = (): string =>
		encodeURIComponent(process.env.REACT_APP_MICROSOFT_REDIRECT_URI!);
	const scope = encodeURIComponent('offline_access user.read');

	const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId()}&response_type=code&redirect_uri=${redirectUri()}&response_mode=query&scope=${scope}&prompt=select_account`;

	window.open(authUrl);
};
