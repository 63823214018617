import { useEffect, useRef, useState } from 'react';

import { message } from 'antd';

type TUseSpeechToTextWebApi = {
	isListeningSpeechToText: boolean;
	speechToTextError?: string;
	toggleListenSpeech: VoidFunction;
	value: string;
	setValue: (value: string) => void;
};

type TUseSpeechToTextWebApiOptions = {
	continuous?: boolean;
};

export const useSpeechToTextWebApi = (
	options: TUseSpeechToTextWebApiOptions = {},
): TUseSpeechToTextWebApi => {
	const { continuous = false } = options;
	const recognitionRef = useRef<SpeechRecognition | null>(null);
	const recognitionListRef = useRef<SpeechGrammarList | null>(null);

	const [isListening, setIsListening] = useState(false);
	const [error, setError] = useState<SpeechRecognitionErrorCode | null>(null);
	const [value, setValue] = useState('');
	const [lastValueChangeTime, setLastValueChangeTime] = useState(Date.now());

	useEffect(() => {
		// if value didn't change for 10 seconds have turn off microphone
		// using Date coz setTimeout waiting isn't correct time
		if (!isListening) return;

		const secondsToWait = 10;
		const milliseconds = 1000;

		setLastValueChangeTime(Date.now());

		const interval = setInterval(() => {
			const currentTime = Date.now();
			const timeDifference = (currentTime - lastValueChangeTime) / milliseconds;

			if (timeDifference >= secondsToWait) {
				recognitionRef.current?.stop();
				clearInterval(interval);
			}
		}, milliseconds);

		return () => clearInterval(interval);
	}, [isListening, lastValueChangeTime]);

	useEffect(() => {
		if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
			message.error('Your browser does not support speech recognition');
			return;
		}

		const SpeechRecognition = window?.webkitSpeechRecognition || window?.SpeechRecognition;

		recognitionRef.current = new SpeechRecognition();
		const recognition = recognitionRef.current;

		if ('webkitSpeechGrammarList' in window || 'SpeechGrammarList' in window) {
			const SpeechGrammarList = window?.SpeechGrammarList || window?.webkitSpeechGrammarList;

			const moods = ['happy', 'sad', 'sleepy', 'angry'];
			const grammar = '#JSGF V1.0; grammar moods; public <moods> = ' + moods.join(' | ') + ';';

			recognitionListRef.current = new SpeechGrammarList();
			const recognitionList = recognitionListRef.current;
			recognitionList.addFromString(grammar, 1);

			recognition.grammars = recognitionList;
		}

		recognition.lang = 'en-US';
		recognition.continuous = continuous;
		recognition.interimResults = false;
		recognition.maxAlternatives = 1;

		recognition.onresult = (event): void => {
			const text = event.results[event.results.length - 1]?.item(0).transcript;
			setValue((prev) => prev + text);
		};

		recognition.onerror = (event): void => {
			setError(event.error);
			setIsListening(false);
		};

		recognition.onend = (): void => {
			setIsListening(false);
		};

		return () => {
			recognition.stop();
			setIsListening(false);
			setError(null);
		};
	}, [continuous]);

	const startListening = (): void => {
		if (recognitionRef.current && !isListening) {
			try {
				recognitionRef.current.start();
			} catch (e) {
				message.error(e?.response?.data?.message || e.message || 'Error!');
			}
			setIsListening(true);
			setError(null);
		}
	};

	const stopListening = (): void => {
		if (recognitionRef.current && isListening) {
			recognitionRef.current.stop();
			setIsListening(false);
			setError(null);
		}
	};

	const toggleListenSpeech = (): void => {
		if (isListening) {
			stopListening();
		} else {
			startListening();
		}
	};

	return {
		isListeningSpeechToText: isListening,
		speechToTextError: error?.toString(),
		toggleListenSpeech,
		value,
		setValue,
	};
};
