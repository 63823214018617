import { FC } from 'react';

import { Checkbox, Flex, Form } from 'antd';

import { initiativesOptions } from './SignUpInitiatives.constants';
import styles from './SignUpInitiatives.module.scss';

const SignUpInitiatives: FC = () => {
	return (
		<Form.Item name="initiatives">
			<Checkbox.Group style={{ width: '100%' }}>
				<Flex vertical gap={8} className={styles.container}>
					{initiativesOptions.map(({ value, label, recommendation, example }) => (
						<Flex key={value} align="baseline" gap={12} className={styles.card}>
							<Checkbox value={value} />
							<Flex vertical align="flex-start">
								<p className={styles.label}>{label}</p>
								<p className={styles.recommendation}>{recommendation}</p>
								<p className={styles.example}>
									<span className={styles.exampleInner}>Example: </span>
									{example}
								</p>
							</Flex>
						</Flex>
					))}
				</Flex>
			</Checkbox.Group>
		</Form.Item>
	);
};
export default SignUpInitiatives;
