import { FC } from 'react';

import { Checkbox, Flex, Typography } from 'antd';
import { ADD_ONS_VALUES } from 'shared/api/subscription.service';

import styles from './AddOnsCard.module.scss';

const { Text, Title } = Typography;

type TAddOnsCardProps = {
	value: keyof typeof ADD_ONS_VALUES;
	addonPrice: number | undefined;
	label: string;
};

const AddOnsCard: FC<TAddOnsCardProps> = ({ value, addonPrice = 0, label }): JSX.Element => {
	return (
		<Flex justify="space-between" align="center" className={styles.container}>
			<Checkbox value={value} className={styles.checkbox}>
				{label}
			</Checkbox>
			<Flex gap={12} align="center">
				<Text className={styles.text}>per month / billed monthly</Text>
				<Title className={styles.price} level={3}>
					${addonPrice / 100}
				</Title>
			</Flex>
		</Flex>
	);
};

export default AddOnsCard;
