import { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react';

import { Form, message } from 'antd';
import { CustomerRequest, SubscriptionDto, SubscriptionRequest } from 'generated-types';
import { pick } from 'lodash';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
	TSubscriptionFormData,
	createSubscription,
	finalizeSubscription,
	validateEmail,
} from 'shared/api/subscription.service';
import { lsUserEmailKey } from 'shared/constants/constants';

type TFormWrapperProps = {
	setFormStepsData: Dispatch<SetStateAction<TSubscriptionFormData>>;
	seSubscriptionResponse: Dispatch<SetStateAction<SubscriptionDto>>;
	setIsProcessing: Dispatch<SetStateAction<boolean>>;
	setFormStep: Dispatch<SetStateAction<number>>;
	formStep: number;
	formStepsData: TSubscriptionFormData | null;
	handleSetRefreshReCaptcha?: VoidFunction;
	recaptchaToken?: string | null;
} & PropsWithChildren;

const FormWrapper: FC<TFormWrapperProps> = ({
	children,
	setFormStepsData,
	seSubscriptionResponse,
	setIsProcessing,
	setFormStep,
	formStep,
	formStepsData,
	handleSetRefreshReCaptcha,
	recaptchaToken,
}): JSX.Element => {
	const [form] = Form.useForm();

	const navigate = useNavigate();

	const { mutateAsync: createSubscriptionAsync } = useMutation({
		mutationFn: (formValues: SubscriptionRequest) => createSubscription(formValues),
	});

	const { mutateAsync: finalizeSubscriptionAsync } = useMutation({
		mutationFn: (payload: CustomerRequest) => finalizeSubscription(payload),
	});

	const { mutateAsync: validateEmailAsync } = useMutation({
		mutationFn: (params: { email: string }) => validateEmail(params),
	});

	const onFinish = async (formValues: TSubscriptionFormData): Promise<void> => {
		try {
			if (formStep === 0) {
				setIsProcessing(true);
				setFormStepsData((prev) => ({ ...prev, ...formValues }));
				const response = await createSubscriptionAsync(
					pick(formValues, ['subscriptionPlanTypes', 'subscriptionAddonTypes']),
				);
				seSubscriptionResponse(response);
				setIsProcessing(false);
				setFormStep(1);
			}

			if (formStep === 1) {
				setFormStepsData((prev) => ({ ...prev, ...formValues }));
				localStorage.setItem(lsUserEmailKey, formValues.email);

				setIsProcessing(true);

				const assignCustomerPayload = pick(formValues, [
					'firstName',
					'lastName',
					'email',
					'password',
				]);
				const response = await validateEmailAsync({ email: formValues.email });

				if (response.isValid && recaptchaToken) {
					await finalizeSubscriptionAsync({ ...assignCustomerPayload, captcha: recaptchaToken });
					navigate(`/success`);
				} else {
					form.setFields([
						{
							name: 'email',
							errors: ['Email already exists'],
						},
					]);
				}

				setIsProcessing(false);
			}
		} catch (e) {
			if (handleSetRefreshReCaptcha) {
				handleSetRefreshReCaptcha();
			}
			setIsProcessing(false);
			message.error(e?.response?.data?.message || e.message || 'Error!');
		}
	};

	return (
		<Form
			requiredMark={false}
			id="registration-form"
			form={form}
			layout="vertical"
			onFinish={onFinish}
			validateTrigger="onChange"
			initialValues={{ ...formStepsData, numberOfLicenses: formStepsData?.numberOfLicenses || 1 }}
		>
			{children}
		</Form>
	);
};

export default FormWrapper;
